import { Suspense, lazy, createContext, useState } from "react";

// interface
import Loading from "ui/loading";
import LoadingUI from "ui/loading/LoadingUI";

// routes
const Routes = lazy(() => import("./routes"));

export const LoadingContext = createContext<any>(null);

function App() {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <LoadingContext.Provider value={[loading, setLoading]}>
      <Suspense fallback={<Loading />}>
        <Routes />
      </Suspense>
      <LoadingUI />
    </LoadingContext.Provider>
  );
}

export default App;
