import { LoadingContext } from "App";
import { useContext } from "react";
import Loading from ".";

const LoadingUI = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useContext(LoadingContext);
  return loading ? <Loading /> : null;
};

export default LoadingUI;
